<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    class="filter"
    ref="form"
    :class="checkRTL"
  >
    <el-row>
      <!-- <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.id.label" :prop="fields.id.name">
          <el-input v-model="model[fields.id.name]"/>
        </el-form-item>
      </el-col> -->

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.name.label"
          :prop="fields.name.name"
        >
          <el-input v-model="model[fields.name.name]" />
        </el-form-item>
      </el-col>

      <!--      <el-col-->
      <!--        style="margin-bottom: -0.41px;"-->
      <!--        :lg="12"-->
      <!--        :md="16"-->
      <!--        :sm="24"-->
      <!--      >-->
      <!--        <el-form-item-->
      <!--          :label="fields.examLowerLimitRange.label"-->
      <!--          :prop="fields.examLowerLimitRange.name"-->
      <!--        >-->
      <!--          <el-date-picker-->
      <!--            type="datetimerange"-->
      <!--            v-model="model[fields.examLowerLimitRange.name]"-->
      <!--          ></el-date-picker>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->

      <!--      <el-col-->
      <!--              style="margin-bottom: -0.41px;"-->
      <!--              :lg="12"-->
      <!--              :md="16"-->
      <!--              :sm="24"-->
      <!--      >-->
      <!--        <el-form-item-->
      <!--                :label="fields.examUpperLimitRange.label"-->
      <!--                :prop="fields.examUpperLimitRange.name"-->
      <!--        >-->
      <!--          <el-date-picker-->
      <!--                  type="datetimerange"-->
      <!--                  v-model="model[fields.examUpperLimitRange.name]"-->
      <!--          ></el-date-picker>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.status.label"
          :prop="fields.status.name"
        >
          <el-select
            placeholder
            v-model="model[fields.status.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.status.options"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <!--      <el-col :lg="12" :md="16" :sm="24">-->
      <!--        <el-form-item-->
      <!--          :label="fields.durationRange.label"-->
      <!--          :prop="fields.durationRange.name"-->
      <!--        >-->
      <!--          <app-number-range-input-->
      <!--            v-model="model[fields.durationRange.name]"-->
      <!--          />-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.examType.label"
          :prop="fields.examType.name"
        >
          <el-select
            placeholder
            v-model="model[fields.examType.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.examType.options"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-col :lg="12" :md="16" :sm="24">
          <el-form-item
            :label="fields.examLanguage.label"
            :prop="fields.examLanguage.name"
          >
            <app-autocomplete-one-input
              :fetchFn="fields.examLanguage.fetchFn"
              v-model="model[fields.examLanguage.name]"
            ></app-autocomplete-one-input>
          </el-form-item>
        </el-col>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.isFree.label"
          :prop="fields.isFree.name"
        >
          <el-select
            placeholder
            v-model="model[fields.isFree.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :label="fields.isFree.yesLabel"
              :value="true">{{ fields.isFree.yesLabel }}</el-option
            >
            <el-option
              :label="fields.isFree.noLabel"
              :value="false">{{ fields.isFree.noLabel }}</el-option
            >
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
                :label="fields.unScored.label"
                :prop="fields.unScored.name"
        >
          <el-select
                  placeholder
                  v-model="model[fields.unScored.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
                    :label="fields.unScored.yesLabel"
                    :value="true"
            >{{ fields.unScored.yesLabel }}</el-option
            >
            <el-option
                    :label="fields.unScored.noLabel"
                    :value="false"
            >{{ fields.unScored.noLabel }}</el-option
            >
          </el-select>
        </el-form-item>
      </el-col>

<!--      <el-col :lg="12" :md="16" :sm="24">-->
<!--        <el-form-item-->
<!--          :label="fields.structure.label"-->
<!--          :prop="fields.structure.name"-->
<!--        >-->
<!--          <app-autocomplete-one-input-->
<!--            :fetchFn="fields.structure.fetchFn"-->
<!--            v-model="model[fields.structure.name]"-->
<!--          ></app-autocomplete-one-input>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
      <!--      <el-col :lg="12" :md="16" :sm="24">-->
      <!--        <el-form-item-->
      <!--          :label="fields.fixQuestions.label"-->
      <!--          :prop="fields.fixQuestions.name"-->
      <!--        >-->
      <!--          <el-select-->
      <!--            placeholder-->
      <!--            v-model="model[fields.fixQuestions.name]"-->
      <!--          >-->
      <!--            <el-option :value="undefined">&#45;&#45;</el-option>-->
      <!--            <el-option-->
      <!--              :label="fields.fixQuestions.yesLabel"-->
      <!--              :value="true"-->
      <!--              >{{ fields.fixQuestions.yesLabel }}</el-option-->
      <!--            >-->
      <!--            <el-option-->
      <!--              :label="fields.fixQuestions.noLabel"-->
      <!--              :value="false"-->
      <!--              >{{ fields.fixQuestions.noLabel }}</el-option-->
      <!--            >-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
        native-type="submit"
        :class="rtlAndPadding"
      >
       <span class="card-title"> <app-i18n code="common.search"></app-i18n> </span>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
        :class="rtlAndPadding"
      >
       <span class="card-title"> <app-i18n code="common.reset"></app-i18n> </span>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { ExamModel } from '@/modules/exam/exam-model';

const { fields } = ExamModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.name,
  fields.examUpperLimitRange,
  fields.examLowerLimitRange,
  fields.status,
  fields.durationRange,
  fields.priceRange,
  fields.examType,
  fields.examLanguage,
  fields.isFree,
  fields.fixQuestions,
  fields.unScored
]);

export default {
  name: 'app-exam-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'exam/list/loading',
      filter: 'exam/list/filter',
      checkRTL: 'exam/form/checkRTL',
      rtlAndPadding: 'exam/form/rtlAndPadding'
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'exam/list/doReset',
      doFetch: 'exam/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.exam.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.exam.list.title"
        ></app-i18n>
      </h1>

      <app-exam-list-toolbar></app-exam-list-toolbar>
      <app-exam-list-filter></app-exam-list-filter>
      <app-exam-list-table></app-exam-list-table>
    </div>
  </div>
</template>

<script>
import ExamListFilter from '@/modules/exam/components/exam-list-filter.vue';
import ExamListTable from '@/modules/exam/components/exam-list-table.vue';
import ExamListToolbar from '@/modules/exam/components/exam-list-toolbar.vue';

export default {
  name: 'app-exam-list-page',

  components: {
    [ExamListFilter.name]: ExamListFilter,
    [ExamListTable.name]: ExamListTable,
    [ExamListToolbar.name]: ExamListToolbar,
  },
};
</script>

<style></style>
